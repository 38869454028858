
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Shop } from '@/types/shop'

@Component({
  components: {},
})
export default class ShopAutocomplete extends Vue {
  @Prop({ default: null }) readonly value!: string | null

  isLoading = false
  items: Shop[] = []
  search = ''
  next: null | string = null
  fetchedAll = false

  get filteredItems() {
    let search = this.search || ''
    let reArr = search.split(/\s/).map(text => new RegExp(text))
    return this.items.filter(({ code, name }) =>
      reArr.every(re => re.test(code) || re.test(name))
    )
  }

  @Watch('search', { immediate: true })
  onSearch() {
    if (this.filteredItems.length === 0) {
      this.fetchData()
    }
  }

  created() {
    this.fetchData()
  }

  async fetchData() {
    if (this.isLoading) return
    if (this.fetchedAll) return
    try {
      this.isLoading = true
      let { results, next } = await (this.next
        ? this.$api.http.get(this.next)
        : this.$api.shops().list({ ordering: 'code', all: true }))
      this.next = next
      this.items = this.items.concat(results)
      this.fetchedAll = !next
      this.$emit('shops', this.items)
      if (!next) this.$emit('all-shops', this.items)
    } catch (err) {
      console.error(err)
    }
    this.isLoading = false
  }
}
